<template>
  <v-container id="info">
    <v-card>
      <v-card-title>Photo</v-card-title>
      <v-card-text class="d-flex">
        <a target="_blank" :download="studentInfo.name+'_signature.jpg'" :href="studentInfo.photo"
           :title="studentInfo.name+'_photo'">
          <v-img max-width="200" class="mx-4" :src="studentInfo.photo"></v-img>
        </a>
        <a target="_blank" :download="studentInfo.name+'_signature.jpg'" :href="studentInfo.signature"
           :title="studentInfo.name+'_signature'">
          <v-img max-width="200" class="mx-4"
                 :src="studentInfo.signature"></v-img>
        </a>
      </v-card-text>
    </v-card>
    <v-card
        class="mx-auto"
        outlined
    >
      <v-card-title class="text--primary">Personal Info</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <div class="col-md-4">
            <v-text-field dense label="Name" class="cursor-pointer" v-model="studentInfo.name"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Name (Bangla)" class="cursor-pointer"
                          v-model="studentInfo.bangla_name"></v-text-field>
          </div>
          <div class="col-md-4" v-if="request">
            <v-text-field dense label="User Account Phone Number" v-model="request.user.phone"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Phone" v-model="studentInfo.phone"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Guardian Phone Number" v-model="studentInfo.contact"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Gender" v-model="studentInfo.gender"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Date of Birth" v-model="studentInfo.dob"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Father's Name" v-model="studentInfo.father_name"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Father's Name (Bangla)" v-model="studentInfo.father_name_bangla"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Mother's Name" v-model="studentInfo.mother_name"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Mother's Name (bangla)" v-model="studentInfo.mother_name_bangla"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="District" v-model="studentInfo.district"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Medium" v-model="studentInfo.medium"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-text-field dense label="Quota" v-model="studentInfo.quota"></v-text-field>
          </div>
          <div class="col-md-4">
            <v-textarea dense label="Others" v-model="studentInfo.others"></v-textarea>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Academic Info</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="save">
          <v-row>
            <div class="col-md-4">
              <v-text-field dense label="SSC Board" v-model="studentInfo.ssc_board"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="SSC Group" v-model="studentInfo.ssc_group"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="SSC Passing Year" v-model="studentInfo.ssc_passing_year"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="SSC Roll" v-model="studentInfo.ssc_roll"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="SSC Registration" v-model="studentInfo.ssc_registration"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="SSC GPA with Optional"
                            v-model="studentInfo.ssc_gpa_with_optional"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="SSC GPA without Optional"
                            v-model="studentInfo.ssc_gpa_without_optional"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="SSC Optional Subject"
                            v-model="studentInfo.ssc_optional_subject"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC Board" v-model="studentInfo.hsc_board"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC Group" v-model="studentInfo.hsc_group"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC Passing Year" v-model="studentInfo.hsc_passing_year"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC Roll" v-model="studentInfo.hsc_roll"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC Registration" v-model="studentInfo.hsc_registration"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC GPA with Optional"
                            v-model="studentInfo.hsc_gpa_with_optional"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC GPA without Optional"
                            v-model="studentInfo.hsc_gpa_without_optional"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field dense label="HSC Optional Subject"
                            v-model="studentInfo.hsc_optional_subject"></v-text-field>
            </div>
            <div class="col-12">
              <v-btn block type="submit" color="primary">Save</v-btn>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Admin Section</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="updateStatus">
          <h4>Current status</h4>
          <div v-html="status"></div>
          <v-textarea v-model="info" label="Write some message"></v-textarea>
          <v-select label="Select step" :items="['accepted','complete']" v-model="step"></v-select>
          <v-btn type="submit" color="primary" block>Update</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      status: '',
      info: '',
      step: '',
      request: {},
      studentInfo: {},
    }
  },
  methods: {
    initialize() {
      const url = 'admin/form-fill-up-request/' + this.$route.params.formFillUpRequestId
      axios.get(url).then((response) => {
        this.status = response.data.status
        this.request = response.data
        this.studentInfo = response.data.user.student_info
      })
    },
    save() {
      const url = 'student-info/' + this.studentInfo.id
      let data = this.studentInfo
      delete data.id
      delete data.user_id
      axios.put(url, data).then((response) => {
        this.studentInfo = response.data
        Swal.fire('Info updated successfully!')
      }).catch(() => {

      })
    },
    updateStatus() {
      const url = 'admin/form-fill-up-request/' + this.$route.params.formFillUpRequestId
      let data = {
        status: this.info,
        step: this.step,
      }
      axios.put(url, data).then(() => {
        this.initialize()
        Swal.fire('Status updated')
      })
    },
    downloadItem({url}) {
      axios.get(url, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'image/*'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = this.studentInfo.name + '.jpg'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    }
  },
  created() {
    this.initialize()
  }
}
</script>
<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}

#info {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>